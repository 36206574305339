import './data-entry-step1.scss'

import moment from 'moment'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useRecoilValue, useResetRecoilState } from 'recoil'
import { toStructureOptions } from 'src/networking/terminals'
import {
  atomQuote,
  selectorAgency,
  selectorCoverages,
  selectorFactors,
  selectorPeriods,
} from 'src/storage'
import { Callbacks, DataEntryStep1Props, InputSelectOption } from 'src/types'
import { addStartDays, formatDate, reverseDate, useBoolean } from 'src/utils'

export const useDataEntryStep1 = (props: DataEntryStep1Props) => {
  const { minMaxContractor, formik } = props
  const {
    values: { coverage_start_date, insured_days },
    setFieldValue,
  } = formik

  const { t } = useTranslation()
  const resetQuote = useResetRecoilState(atomQuote)
  const agency = useRecoilValue(selectorAgency)
  const factors = useRecoilValue(selectorFactors)
  const periods = useRecoilValue(selectorPeriods)
  const coverages = useRecoilValue(selectorCoverages)

  const [closePickerTrigger, , , toggleClosePickerTrigger] = useBoolean()

  ////////////////////////////////////////////////////////////////////////////////////////////////////

  const seasonalEndDate = new Date(reverseDate(factors?.['2']?.maxDate || '', '/', '-'))

  const MIN_DATE_PERIOD = periods?.['3']?.min ? periods?.['3']?.min : 0

  ////////////////////////////////////////////////////////////////////////////////////////////////////

  const coverageStartDate = reverseDate(coverage_start_date)
  const coverageStartDatePeriod = formatDate(addStartDays(coverageStartDate, MIN_DATE_PERIOD))

  const coverageStartDateDisabledFrom = new Date()
  const coverageStartDateDisabledTo = seasonalEndDate
  const coverageEndDateDisabledFrom = new Date(reverseDate(coverageStartDatePeriod, '/', '-'))
  const coverageEndDateDisabledTo = seasonalEndDate
  const coverageEndDate = moment(seasonalEndDate).format('DD/MM/YYYY')

  ////////////////////////////////////////////////////////////////////////////////////////////////////

  useEffect(() => {
    resetQuote()
  }, [])

  useEffect(() => {
    if (insured_days) {
      setFieldValue('coverage_start_date', moment().format('DD/MM/YYYY'))

      if (
        (insured_days === 'id_period' && formik.values.coverage_end_date === coverage_start_date) ||
        formik.values.coverage_end_date === coverageEndDate
      ) {
        setFieldValue('coverage_end_date', '')
      }

      toggleClosePickerTrigger()
    }
  }, [insured_days])

  useEffect(() => {
    if (coverage_start_date) {
      const callbacks: Callbacks = {
        id_daily: () => {
          setFieldValue('coverage_start_date', coverage_start_date)
          setFieldValue(
            'coverage_end_date',
            formatDate(addStartDays(reverseDate(coverage_start_date), 0))
          )
        },
        id_seasonal: () => setFieldValue('coverage_end_date', formatDate(seasonalEndDate)),
        id_period: () => {
          setFieldValue('coverage_start_date', coverage_start_date)
        },
      }
      callbacks[insured_days]?.()
    }
  }, [coverage_start_date, insured_days])

  ////////////////////////////////////////////////////////////////////////////////////////////////////

  const [insuredNumberOptions, setInsuredNumberOptions] = useState<InputSelectOption[]>([])

  /**
   * useEffect
   */

  useEffect(() => {
    const options: InputSelectOption[] = []
    Array.from({ length: minMaxContractor?.max || 1 }, (_, item) =>
      options?.push({
        id: (item + 1)?.toString(),
        value: (item + 1)?.toString(),
      })
    )

    setInsuredNumberOptions(options)
  }, [minMaxContractor])

  /**
   * Functions
   */
  const onSelectPackage = (value: string) => {
    setFieldValue('coverage_type_radios', value)
  }

  return {
    t,
    agency,
    closePickerTrigger,
    coverageStartDateDisabledFrom,
    coverageStartDateDisabledTo,
    coverageEndDateDisabledFrom,
    coverageEndDateDisabledTo,
    insuredNumberOptions,
    onSelectPackage,
    toStructureOptions,
  }
}
