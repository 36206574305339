import { Image } from 'react-bootstrap'
import { CircleKo, CircleOk, Infosnow } from 'src/assets'
import { ScrollTopRoot } from 'src/roots'
import { DataEntryStep1Props, typedMemo } from 'src/types'
import { InputDate, InputSelect, InputText } from 'src/ui/widgets'

import { useDataEntryStep1 } from './data-entry-step1.hook'

export const DataEntryStep1 = typedMemo((props: DataEntryStep1Props) => {
  const {
    daysOptions,
    insuredLimit,
    formik: { values, errors, touched, setFieldValue, handleChange, handleBlur, setFieldTouched },
    validationSchema,
  } = props

  const {
    t,
    agency,
    closePickerTrigger,
    coverageStartDateDisabledFrom,
    coverageStartDateDisabledTo,
    coverageEndDateDisabledFrom,
    coverageEndDateDisabledTo,
    onSelectPackage,
    toStructureOptions,
  } = useDataEntryStep1(props)

  const PeriodTypeDates = (
    <div>
      <div className="col-sm-12 p-0 m-0">
        <InputDate
          name="coverage_start_date"
          handleChange={handleChange}
          handleBlur={handleBlur}
          setFieldValue={setFieldValue}
          setFieldTouched={setFieldTouched}
          validationSchema={validationSchema}
          touched={touched.coverage_start_date}
          value={values.coverage_start_date}
          error={errors.coverage_start_date}
          label={t('data_entry_step_1.coverage_start_date')}
          placeholder={t('data_entry_step_1.coverage_start_date_placeholder')}
          disabled={values.insured_days === 'id_seasonal'}
          disabledFrom={coverageStartDateDisabledFrom}
          disabledTo={coverageStartDateDisabledTo}
          classes="m-0 p-0"
          closePickerTrigger={closePickerTrigger}
        />
      </div>

      <div className="col-sm-12 p-0 m-0">
        <InputDate
          name="coverage_end_date"
          handleChange={handleChange}
          handleBlur={handleBlur}
          setFieldValue={setFieldValue}
          setFieldTouched={setFieldTouched}
          validationSchema={validationSchema}
          touched={touched.coverage_end_date}
          value={values.coverage_end_date}
          error={errors.coverage_end_date}
          label={t('data_entry_step_1.coverage_end_date')}
          placeholder={t('data_entry_step_1.coverage_end_date_placeholder')}
          disabled={values.insured_days !== 'id_period'}
          disabledFrom={coverageEndDateDisabledFrom}
          disabledTo={coverageEndDateDisabledTo}
          classes="m-0 p-0"
          closePickerTrigger={closePickerTrigger}
        />
      </div>
    </div>
  )

  const PeriodTypeConvention = (
    <div>
      {agency?.structures && agency?.structures?.length > 0 && (
        <div className="col-sm-12 m-0 mt-2 font-15">
          <InputSelect
            name="convention"
            validationSchema={validationSchema}
            setFieldValue={setFieldValue}
            options={toStructureOptions(agency?.structures)}
            touched={touched.convention}
            value={values.convention}
            error={errors.convention}
            label={t('data_entry_step_1.convention')}
            placeholder={t('data_entry_step_1.convention_placeholder')}
          />
        </div>
      )}
    </div>
  )

  const InsuredDays = (
    <>
      <div className="col-12 d-md-flex">
        <div className="col-12 p-0 m-0">
          <InputSelect
            name="insured_days"
            validationSchema={validationSchema}
            setFieldValue={setFieldValue}
            options={daysOptions}
            touched={touched.insured_days}
            value={values.insured_days}
            error={errors.insured_days}
            label={t('data_entry_step_1.coverage_insured_days')}
          />
        </div>
      </div>
      {(values?.insured_days === 'id_period' ||
        Number(values?.insured_number) + Number(values?.insured_number_under14) >=
          insuredLimit) && <div className="col-12 col-md-12 p-0 m-0 pb-3">{PeriodTypeDates}</div>}
      <div className="col-12 col-md-12 p-0 m-0 pb-3">{PeriodTypeConvention}</div>
    </>
  )

  const CoverageInsuredNumber = (
    <div className="col-12 p-0 m-0">
      <InputText
        name="insured_number"
        handleChange={handleChange}
        handleBlur={handleBlur}
        validationSchema={validationSchema}
        touched={touched.insured_number}
        value={values.insured_number}
        error={errors.insured_number}
        type="number"
        label={t('data_entry_step_1.coverage_insured_number')}
        placeholder={t('data_entry_step_1.coverage_insured_number_placeholder')}
        classesError="font-15 m-0 c-862633"
        classesLabel="font-15"
      />
    </div>
  )

  const CoverageInsuredNumberUnder14 = (
    <div className="col-12 p-0 m-0">
      <InputText
        name="insured_number_under14"
        handleChange={handleChange}
        handleBlur={handleBlur}
        validationSchema={validationSchema}
        touched={touched.insured_number_under14}
        value={values.insured_number_under14}
        error={errors.insured_number_under14}
        type="number"
        label={t('data_entry_step_1.coverage_insured_number_under14')}
        placeholder={t('data_entry_step_1.coverage_insured_number_placeholder')}
        classesError="font-15 m-0 c-862633"
        classesLabel="font-15"
      />
    </div>
  )

  const PeriodType = (
    <div className="pl-md-3 mb-2 mb-md-1 font-14">
      {CoverageInsuredNumber}
      {CoverageInsuredNumberUnder14}
      {InsuredDays}
    </div>
  )

  const CoverageTypes = (
    <div>
      <div className="font-16 mb-3">{t('data_entry_step_1.coverage_type_title')}*</div>
      <div
        className={`p-3 d-md-flex align-items-center mb-4 cursor-pointer ${
          values.coverage_type_radios === '1' ? 'package-box-selected' : 'white-box'
        }`}
        onClick={() => onSelectPackage('1')}
      >
        <div className="col-md-4">
          <div className="c-3636b1 text-uppercase font-16-bold">
            {t('data_entry_step_1.coverage_type_base_title')}
          </div>
          <div className="font-14">{t('data_entry_step_1.coverage_type_subtitle')}</div>
        </div>
        <div className="col-md-8 d-flex align-items-baseline justify-content-around font-14-bold mt-3 mt-md-0">
          <div className="col-5 col-md-0">
            <div className="d-flex justify-content-center">
              <Image src={CircleOk} width={24} height={24} className="filter-3636b1 margin-icon" />
            </div>
            <div className="text-center">{t('data_entry_step_1.coverage_type_1')}</div>
          </div>
          <div className="col-4 col-md-0">
            <div className="d-flex justify-content-center">
              <Image src={CircleOk} width={24} height={24} className="filter-3636b1 margin-icon" />
            </div>
            <div className="text-center">{t('data_entry_step_1.coverage_type_2')}</div>
          </div>
          <div className="opacity-25 col-3 col-md-0">
            <div className="d-flex justify-content-center">
              <Image src={CircleKo} width={24} height={24} className="filter-3636b1 margin-icon" />
            </div>
            <div className="text-center">{t('data_entry_step_1.coverage_type_3')}</div>
          </div>
        </div>
      </div>

      <div
        className={`p-3 d-md-flex align-items-center mb-4 cursor-pointer ${
          values.coverage_type_radios === '2' ? 'package-box-selected' : 'white-box'
        }`}
        onClick={() => onSelectPackage('2')}
      >
        <div className="col-md-4">
          <div className="c-3636b1 text-uppercase font-16-bold">
            {t('data_entry_step_1.coverage_type_plus_title')}
          </div>
          <div className="font-14">{t('data_entry_step_1.coverage_type_subtitle')}</div>
        </div>
        <div className="col-md-8 d-flex align-items-baseline justify-content-around font-14-bold mt-3 mt-md-0">
          <div className="col-5 col-md-0">
            <div className="d-flex justify-content-center">
              <Image src={CircleOk} width={24} height={24} className="filter-3636b1 margin-icon" />
            </div>
            <div className="text-center">{t('data_entry_step_1.coverage_type_1')}</div>
          </div>
          <div className="col-4 col-md-0">
            <div className="d-flex justify-content-center">
              <Image src={CircleOk} width={24} height={24} className="filter-3636b1 margin-icon" />
            </div>
            <div className="text-center">{t('data_entry_step_1.coverage_type_2')}</div>
          </div>
          <div className="col-3 col-md-0">
            <div className="d-flex justify-content-center">
              <Image src={CircleOk} width={24} height={24} className="filter-3636b1 margin-icon" />
            </div>
            <div className="text-center">{t('data_entry_step_1.coverage_type_3')}</div>
          </div>
        </div>
      </div>
    </div>
  )

  const InputsSection = (
    <div className="col-12 col-md-6 p-0 px-md-3 pl-md-3 mb-2 mb-md-1 font-14">
      {CoverageTypes}
      {PeriodType}
    </div>
  )

  const LabelsSection = (
    <div className="col-12 col-md-6 p-0 margin-info pe-md-3">
      <div className="white-box-info p-3">
        <div className="mb-3">
          <div className="font-16-bold mb-3">
            {t('data_entry_step_1.coverage_description_start')}
          </div>
        </div>
        <div className="mb-4">
          <div className="d-flex align-items-center mb-2">
            <div className="bullet-info col-1 me-3" />
            <div className="font-16">{t('data_entry_step_1.coverage_description_bullet_1')}</div>
          </div>
          <div className="d-flex align-items-center mb-2">
            <div className="bullet-info col-1 me-3" />
            <div className="font-16">{t('data_entry_step_1.coverage_description_bullet_2')}</div>
          </div>
          <div className="d-flex align-items-center mb-2">
            <div className="bullet-info col-1 me-3" />
            <div className="font-16">{t('data_entry_step_1.coverage_description_bullet_3')}</div>
          </div>
        </div>
        <div>
          <Image src={Infosnow} alt="Itasnow" width="100%" height="100%" />
        </div>
      </div>
    </div>
  )

  return (
    <div>
      <ScrollTopRoot />
      <div className="d-md-flex">
        {InputsSection}
        {LabelsSection}
      </div>
    </div>
  )
})
