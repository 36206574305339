/* eslint-disable complexity */
import { FormikErrors } from 'formik'
import { COVERAGE_TYPE_DAYS } from 'src/config'
import { FormikMainInsured, Nationality, Optional, QuoteUserTransaction, Voidable } from 'src/types'

export const insertFormikUserTransaction = (
  userQuote: Optional<QuoteUserTransaction>,
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => Promise<FormikErrors<FormikMainInsured>> | Promise<void>
) => {
  const fields = [
    { field: 'coverage_type_radios', value: userQuote?.coverage || '1' },
    {
      field: 'coverage_start_date',
      value: userQuote?.period?.from?.replaceAll('-', '/')?.split('/').reverse().join('/'),
    },
    {
      field: 'coverage_end_date',
      value: userQuote?.period?.to?.replaceAll('-', '/')?.split('/').reverse().join('/'),
    },
    { field: 'convention', value: '' },
    { field: 'insured_name', value: userQuote?.contractor?.name || '' },
    { field: 'insured_surname', value: userQuote?.contractor?.surname || '' },
    { field: 'insured_email', value: userQuote?.contractor?.mainContact?.value || '' },
    { field: 'insured_email_confirm', value: userQuote?.contractor?.mainContact?.value || '' },
    {
      field: 'insured_phone.formattedPhone',
      value:
        userQuote?.contractor?.otherContacts?.find((contact) => contact?.type === 'mobile')
          ?.value || '',
    },
    { field: 'insured_cap', value: userQuote?.contractor?.cap || '' },
    {
      field: 'insured_vat_number',
      value: userQuote?.contractor?.insured_vat_number?.toUpperCase() || '',
    },
    {
      field: 'insured_nationality',
      value: (userQuote?.contractor?.nationality?.toUpperCase() || 'IT') as Voidable<Nationality>,
    },
    { field: 'insured_fc_question', value: userQuote?.contractor?.fiscalCode ? 'yes_id' : 'no_id' },
    {
      field: 'insured_birth',
      value: userQuote?.contractor?.birthDate?.split('/').reverse().join('/') || '',
    },
    { field: 'insured_birth_nation', value: userQuote?.contractor?.birthNation || '' },
    { field: 'insured_birth_place', value: userQuote?.contractor?.birthPlace || '' },
    { field: 'insured_gender', value: userQuote?.contractor?.gender || '' },
    { field: 'insured_days', value: COVERAGE_TYPE_DAYS[userQuote?.period?.type || '1'] },
    { field: 'insured_fc', value: userQuote?.contractor?.fiscalCode || '' },
    { field: 'consent_td', value: userQuote?.contractor?.tdConsent || false },
    { field: 'consent_privacy', value: userQuote?.contractor?.privacyConsent || false },
    { field: 'consent_marketing', value: userQuote?.contractor?.marketingConsent || false },
    { field: 'consent_profiling', value: userQuote?.contractor?.profilingConsent || false },
    { field: 'consent_communication', value: userQuote?.contractor?.communicationConsent || false },
    { field: 'informative_vision', value: false },
    { field: 'discount', value: '' },
    { field: 'insured_number', value: userQuote?.insuredNumber },
    { field: 'insured_number_under14', value: userQuote?.insuredNumberUnder14 },
    { field: 'is_family', value: false },
  ]

  fields.forEach(({ field, value }) => setFieldValue(field, value))

  if (userQuote?.assets && userQuote?.assets.length > 0) {
    userQuote.assets.forEach((asset, index) => {
      const assetFields = [
        { field: `insured_list.${index}.name`, value: asset?.name || '' },
        { field: `insured_list.${index}.surname`, value: asset?.surname || '' },
        { field: `insured_list.${index}.nationality`, value: asset?.nationality || '' },
        { field: `insured_list.${index}.fc`, value: asset?.fiscalCode || '' },
        {
          field: `insured_list.${index}.birth`,
          value: asset?.birthDate?.split('/').reverse().join('/') || '',
        },
        { field: `insured_list.${index}.birth_nation`, value: asset?.birthNation || '' },
        { field: `insured_list.${index}.birth_place`, value: asset?.birthPlace || '' },
        { field: `insured_list.${index}.gender`, value: asset?.gender || '' },
        {
          field: `insured_list.${index}.fc_question`,
          value: asset?.fiscalCode ? 'yes_id' : 'no_id',
        },
      ]

      assetFields.forEach(({ field, value }) => setFieldValue(field, value))
    })
  } else {
    setFieldValue('insured_list', [])
  }
}
