/* eslint-disable complexity */
import { ChangeEvent } from 'react'
import { Typeahead } from 'react-bootstrap-typeahead'
import { Option } from 'react-bootstrap-typeahead/types/types'
import PhoneInput from 'react-phone-input-2'
import { lowerLanguage } from 'src/i18n'
import { ScrollTopRoot } from 'src/roots'
import { CustomOption, Formik, typedMemo } from 'src/types'
import { FormikMainInsuredGroup } from 'src/types/networking/terminals/group.types'
import { Accordion, Button, InputCheckbox, InputDate, InputRadio, InputText } from 'src/ui/widgets'
import { AnyObjectSchema } from 'yup'

import { useEntryGroupStep3 } from './entry-group-step3.hook'

export const EntryGroupStep3 = typedMemo(
  ({
    fatherFormik,
    validationSchema,
  }: {
    fatherFormik: Formik<FormikMainInsuredGroup>
    validationSchema: AnyObjectSchema
  }) => {
    const {
      t,
      isMobile,
      genderOptions,
      insuredOptions,
      isSelectedInsured,
      fcQuestionOptions,
      nationalityOptions,
      onChangePhone,
      onResetInsured,
      onSelectedInsured,
      openInformativePersonalDataLink,
    } = useEntryGroupStep3({ fatherFormik })

    const { touched, errors, setFieldValue, setFieldTouched, handleBlur, handleChange } =
      fatherFormik

    const { mainInsured, originGroup } = fatherFormik?.values ?? {}

    const Title = (
      <div className="col-12 col-md-6 p-0 px-md-3 pl-md-3 mb-2 mb-md-1 font-20-bold">
        {t('entry_group_step_3.title')}
      </div>
    )

    const RadioForm = (
      <div className="col-12 col-md-6 p-0 px-md-3 pl-md-3 mb-2 mb-md-1 font-20-bold">
        <InputRadio
          name="mainInsured.isInsured"
          handleChange={(event: ChangeEvent<HTMLInputElement>) => {
            handleChange(event), onResetInsured()
          }}
          handleBlur={handleBlur}
          options={insuredOptions || []}
          touched={touched?.mainInsured?.isInsured}
          value={mainInsured?.isInsured}
          error={
            touched?.mainInsured?.isInsured && errors?.mainInsured?.isInsured
              ? errors?.mainInsured?.isInsured
              : ''
          }
          vertically
          label={t('entry_group_step_3.radio_title')}
          classes="m-0 p-0 mt-4 mb-3"
          classesLabel="m-0 p-0 font-15-bold d-flex align-items-center mb-3"
          classesOption="pb-3"
        />
      </div>
    )

    const SearchInput = (
      <div className="col-12 col-md-6 p-0 px-md-3 pl-md-3 mb-2 mb-md-1">
        <Typeahead
          id="typeahead"
          labelKey={(option: Option) => {
            const customOption = option as CustomOption
            return `${customOption.name} ${customOption.surname} - ${customOption.fiscalCode}`
          }}
          onChange={(event) => {
            onSelectedInsured(event)
          }}
          options={originGroup as CustomOption[]}
          placeholder={t('entry_group_step_3.find_insured')}
          style={{ height: '44px' }}
        />
      </div>
    )

    const Insured = (
      <div>
        <div className="d-block d-md-flex">
          <div
            className={`${
              !touched?.mainInsured?.name || !errors?.mainInsured?.name ? 'pb-3' : ''
            } col-sm-12 col-md-6 p-0 px-md-3 pl-md-3 pr-md-3`}
          >
            <InputText
              name="mainInsured.name"
              handleChange={handleChange}
              handleBlur={handleBlur}
              validationSchema={validationSchema}
              touched={touched?.mainInsured?.name}
              value={mainInsured?.name}
              error={
                touched?.mainInsured?.name && errors?.mainInsured?.name
                  ? errors?.mainInsured?.name
                  : ''
              }
              label={t('data_entry_step_2.insured_name')}
              placeholder={t('data_entry_step_2.insured_name_placeholder')}
              disabled={mainInsured?.isInsured === 'id_yes'}
            />
          </div>

          <div
            className={`${
              !touched?.mainInsured?.surname || !errors?.mainInsured?.surname ? 'pb-3' : ''
            } col-sm-12 col-md-6 p-0 px-md-3 pl-md-3 pr-md-3`}
          >
            <InputText
              name="mainInsured.surname"
              handleChange={handleChange}
              handleBlur={handleBlur}
              validationSchema={validationSchema}
              touched={touched?.mainInsured?.surname}
              value={mainInsured?.surname}
              error={
                touched?.mainInsured?.surname && errors?.mainInsured?.surname
                  ? errors?.mainInsured?.surname
                  : ''
              }
              label={t('data_entry_step_2.insured_surname')}
              placeholder={t('data_entry_step_2.insured_surname_placeholder')}
              disabled={mainInsured?.isInsured === 'id_yes'}
            />
          </div>
        </div>

        <div className="d-block d-md-flex">
          <div
            className={`${
              !touched?.mainInsured?.email || !errors?.mainInsured?.email ? 'pb-3' : ''
            } col-sm-12 col-md-6 p-0 px-md-3 pl-md-3 pr-md-3`}
          >
            <InputText
              name="mainInsured.email"
              handleChange={handleChange}
              handleBlur={handleBlur}
              validationSchema={validationSchema}
              touched={touched?.mainInsured?.email}
              value={mainInsured?.email}
              error={
                touched?.mainInsured?.email && errors?.mainInsured?.email
                  ? errors?.mainInsured?.email
                  : ''
              }
              label={t('data_entry_step_2.insured_email')}
              placeholder={t('data_entry_step_2.insured_email_placeholder')}
            />
          </div>

          <div
            className={`${
              !touched?.mainInsured?.emailConfirm || !errors?.mainInsured?.emailConfirm
                ? 'pb-3'
                : ''
            } col-sm-12 col-md-6 p-0 px-md-3 pl-md-3 pr-md-3`}
          >
            <InputText
              name="mainInsured.emailConfirm"
              handleChange={handleChange}
              handleBlur={handleBlur}
              validationSchema={validationSchema}
              touched={touched?.mainInsured?.emailConfirm}
              error={
                touched?.mainInsured?.emailConfirm && errors?.mainInsured?.emailConfirm
                  ? errors?.mainInsured?.emailConfirm
                  : ''
              }
              value={mainInsured?.emailConfirm}
              label={t('data_entry_step_2.insured_email_confirm')}
              placeholder={t('data_entry_step_2.insured_email_confirm_placeholder')}
              onPaste={(event) => {
                event.preventDefault()
                return false
              }}
              onCopy={(event) => {
                event.preventDefault()
                return false
              }}
            />
          </div>
        </div>

        <div className="d-block d-md-flex">
          <div className="col-sm-12 col-md-6 p-0 px-md-3 pl-md-3 pr-md-3">
            <label htmlFor="input-insured_phone" className="input-text--label d-block">
              {t('data_entry_step_2.insured_phone')}
            </label>
            <div
              className={
                (errors?.mainInsured?.phone as any)?.formattedPhone
                  ? 'phone-style-error'
                  : 'phone-style pb-3'
              }
            >
              <PhoneInput
                country={lowerLanguage === 'it' ? 'it' : lowerLanguage === 'en' ? 'gb' : 'de'}
                placeholder={t('data_entry_step_2.insured_phone_placeholder')}
                value={mainInsured?.phone?.formattedPhone}
                onChange={onChangePhone}
              />
            </div>
            {(errors?.mainInsured?.phone as any)?.formattedPhone && (
              <div className="font-14 c-862633">
                {(errors?.mainInsured?.phone as any)?.formattedPhone}
              </div>
            )}
          </div>

          <div className="col-sm-12 col-md-6 p-0 px-md-3 pl-md-3 pr-md-3">
            <InputText
              name="mainInsured.cap"
              handleChange={handleChange}
              handleBlur={handleBlur}
              validationSchema={validationSchema}
              touched={touched?.mainInsured?.cap}
              value={mainInsured?.cap}
              error={errors?.mainInsured?.cap}
              label={t('data_entry_step_2.insured_cap')}
              placeholder={t('data_entry_step_2.insured_cap_placeholder')}
            />
          </div>
        </div>
        {/* <div className="d-block d-md-flex">
          <div className="col-sm-12 col-md-6 p-0 px-md-3 pl-md-3 pr-md-3">
            <InputText
              name="mainInsured.vatNumber"
              handleChange={handleChange}
              handleBlur={handleBlur}
              validationSchema={validationSchema}
              touched={touched?.mainInsured?.vatNumber}
              value={mainInsured?.vatNumber}
              error={errors?.mainInsured?.vatNumber}
              label={t('data_entry_step_2.insured_vat_number')}
              placeholder={t('data_entry_step_2.insured_vat_number_placeholder')}
            />
          </div>
        </div> */}

        <div className="d-block d-md-flex">
          <div className="col-sm-12 col-md-6 p-0 px-md-3 pl-md-3 pr-md-3">
            <InputRadio
              name="mainInsured.nationality"
              handleChange={handleChange}
              handleBlur={handleBlur}
              options={nationalityOptions}
              validationSchema={validationSchema}
              touched={touched?.mainInsured?.nationality}
              value={mainInsured?.nationality}
              error={errors?.mainInsured?.nationality}
              label={t('data_entry_step_2.insured_nationality')}
              classes="m-0 p-0"
              classesLabel="m-0 p-0 font-16 d-flex align-items-center mb-3"
              disabled={mainInsured?.isInsured === 'id_yes'}
            />
          </div>
        </div>

        <div className="d-block d-md-flex">
          <div className="col-sm-12 col-md-6 p-0 px-md-3 pl-md-3 pr-md-3">
            <InputRadio
              name="mainInsured.fiscalCodeQuestion"
              handleChange={handleChange}
              handleBlur={handleBlur}
              options={fcQuestionOptions}
              validationSchema={validationSchema}
              touched={touched?.mainInsured?.fiscalCodeQuestion}
              value={mainInsured?.fiscalCodeQuestion}
              error={
                errors?.mainInsured?.fiscalCodeQuestion && touched?.mainInsured?.fiscalCodeQuestion
                  ? errors?.mainInsured?.fiscalCodeQuestion
                  : ''
              }
              label={t('data_entry_step_2.insured_fc_question')}
              classesLabel="m-0 p-0 font-16 d-flex align-items-center mb-3"
              classes="m-0 p-0 mb-3"
              disabled={mainInsured?.isInsured === 'id_yes'}
            />
          </div>
        </div>

        {mainInsured?.fiscalCodeQuestion === 'yes_id' && (
          <div className="d-block d-md-flex">
            <div className="col-sm-12 col-md-6 p-0 px-md-3 pl-md-3 pr-md-3">
              <InputText
                name="mainInsured.fiscalCode"
                handleChange={handleChange}
                handleBlur={handleBlur}
                validationSchema={validationSchema}
                touched={touched?.mainInsured?.fiscalCode}
                value={mainInsured?.fiscalCode?.toUpperCase()}
                error={errors?.mainInsured?.fiscalCode}
                label={`${t('data_entry_step_2.insured_fc')}*`}
                placeholder={t('data_entry_step_2.insured_fc_placeholder')}
                disabled={mainInsured?.isInsured === 'id_yes'}
              />
            </div>
          </div>
        )}

        {mainInsured?.fiscalCodeQuestion === 'no_id' && (
          <div>
            <div className="d-block d-md-flex">
              <div className="col-sm-12 col-md-6 p-0 px-md-3 pl-md-3 pr-md-3">
                <InputDate
                  name="mainInsured.birthDate"
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  setFieldValue={setFieldValue}
                  setFieldTouched={setFieldTouched}
                  validationSchema={validationSchema}
                  touched={touched?.mainInsured?.birthDate}
                  value={mainInsured?.birthDate}
                  error={errors?.mainInsured?.birthDate}
                  label={`${t('data_entry_step_2.insured_birth')}*`}
                  placeholder={t('data_entry_step_2.insured_birth_placeholder')}
                  disabledTo={new Date()}
                  classes="m-0 p-0"
                  disabled={mainInsured?.isInsured === 'id_yes'}
                />
              </div>

              <div className="col-sm-12 col-md-6 p-0 px-md-3 pl-md-3 pr-md-3">
                <InputText
                  name="mainInsured.birthNation"
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  validationSchema={validationSchema}
                  touched={touched?.mainInsured?.birthNation}
                  value={mainInsured?.birthNation}
                  error={errors?.mainInsured?.birthNation}
                  label={`${t('data_entry_step_2.insured_birth_nation')}*`}
                  placeholder={t('data_entry_step_2.insured_birth_nation_placeholder')}
                  disabled={mainInsured?.isInsured === 'id_yes'}
                />
              </div>
            </div>

            <div className="d-block d-md-flex">
              <div className="col-sm-12 col-md-6 p-0 px-md-3 pl-md-3 pr-md-3">
                <InputText
                  name="mainInsured.birthPlace"
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  validationSchema={validationSchema}
                  touched={touched?.mainInsured?.birthPlace}
                  value={mainInsured?.birthPlace}
                  error={errors?.mainInsured?.birthPlace}
                  label={`${t('data_entry_step_2.insured_birth_place')}*`}
                  placeholder={t('data_entry_step_2.insured_birth_place_placeholder')}
                  disabled={mainInsured?.isInsured === 'id_yes'}
                />
              </div>

              <div className="col-sm-12 col-md-6 p-0 px-md-3 pl-md-3 pr-md-3">
                <InputRadio
                  name="mainInsured.gender"
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  options={genderOptions}
                  validationSchema={validationSchema}
                  touched={touched?.mainInsured?.gender}
                  value={mainInsured?.gender}
                  error={errors?.mainInsured?.gender}
                  label={`${t('data_entry_step_2.insured_gender')}*`}
                  classesLabel="m-0 p-0 font-16 d-flex align-items-center mb-3"
                  classes="m-0 p-0 mb-3"
                  disabled={mainInsured?.isInsured === 'id_yes'}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    )

    const InsuranceConsents = (
      <div className="mt-3">
        <div className="col-sm-12 p-0 px-md-3">
          <div className="d-md-flex align-items-stretch">
            <div className="col-md-6 pe-md-4">
              <InputCheckbox
                name="mainInsured.consentPrivacy"
                handleChange={handleChange}
                handleBlur={handleBlur}
                validationSchema={validationSchema}
                touched={touched?.mainInsured?.consentPrivacy}
                value={mainInsured?.consentPrivacy}
                error={
                  errors?.mainInsured?.consentPrivacy && touched?.mainInsured?.consentPrivacy
                    ? errors?.mainInsured?.consentPrivacy
                    : ''
                }
                label={t('data_entry_step_2.consent_privacy_label')}
                classesLabel="m-0 font-16 d-flex align-items-baseline"
              />

              <div className="d-inline-block">
                <div className="py-md-2 font-15 display-contents">
                  {t('data_entry_step_2.consent_privacy_text_1')}
                </div>
                <div className="py-md-2 display-contents c-891124 cursor-pointer">
                  <Button
                    flavor="transparentPrimary"
                    text={t('data_entry_step_2.consent_privacy_link')}
                    classes="p-0 display-contents"
                    classesText="font-15 d-inline border-underline-link"
                    onPress={openInformativePersonalDataLink}
                  />
                </div>
                <div className="py-md-2 font-15 display-contents">
                  {t('data_entry_step_2.consent_privacy_text_2')}
                </div>
              </div>
            </div>

            <div className="col-md-6 ps-md-4">
              <InputCheckbox
                name="mainInsured.consentProfiling"
                handleChange={handleChange}
                handleBlur={handleBlur}
                validationSchema={validationSchema}
                touched={touched?.mainInsured?.consentProfiling}
                value={mainInsured?.consentProfiling}
                label={t('data_entry_step_2.consent_profiling_label')}
                classesLabel="m-0 font-16 d-flex align-items-baseline mt-3"
              />

              {isMobile ? (
                <Accordion
                  title={t('data_entry_step_3.consent_accordion_label')}
                  classesTitle="m-0 p-0 font-16 me-3"
                  classes="max-w-fit-content"
                  classesHead="m-0 p-0 pb-2 mt-3 d-flex justify-content-between align-items-center justify-content-md-start flex-nowrap"
                >
                  <div className="d-inline-block">
                    <div className="py-md-2 font-15 display-contents">
                      {t('data_entry_step_2.consent_profiling_value')}
                    </div>
                    <div className="py-md-2 display-contents c-891124 cursor-pointer">
                      <Button
                        flavor="transparentPrimary"
                        text={t('data_entry_step_2.consent_privacy_link_2')}
                        classes="p-0 display-contents"
                        classesText="font-15 d-inline border-underline-link"
                        onPress={openInformativePersonalDataLink}
                      />
                    </div>
                    <div className="py-md-2 font-15 display-contents">
                      {t('data_entry_step_2.consent_ending')}
                    </div>
                  </div>
                </Accordion>
              ) : (
                <div className="d-inline-block">
                  <div className="py-md-2 font-15 display-contents">
                    {t('data_entry_step_2.consent_profiling_value')}
                  </div>
                  <div className="py-md-2 display-contents c-891124 cursor-pointer">
                    <Button
                      flavor="transparentPrimary"
                      text={t('data_entry_step_2.consent_privacy_link_2')}
                      classes="p-0 display-contents"
                      classesText="font-15 d-inline border-underline-link"
                      onPress={openInformativePersonalDataLink}
                    />
                  </div>
                  <div className="py-md-2 font-15 display-contents">
                    {t('data_entry_step_2.consent_ending')}
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className="d-md-flex align-items-stretch">
            <div className="col-md-6 pe-md-4">
              <InputCheckbox
                name="mainInsured.consentMarketing"
                handleChange={handleChange}
                handleBlur={handleBlur}
                validationSchema={validationSchema}
                touched={touched?.mainInsured?.consentMarketing}
                value={mainInsured?.consentMarketing}
                error={errors?.mainInsured?.consentMarketing}
                label={t('data_entry_step_2.consent_marketing_label')}
                classesLabel="m-0 font-16 d-flex align-items-baseline mt-3"
              />

              {isMobile ? (
                <Accordion
                  title={t('data_entry_step_3.consent_accordion_label')}
                  classesTitle="m-0 p-0 font-16 me-3"
                  classes="max-w-fit-content"
                  classesHead="m-0 p-0 pb-2 mt-3 d-flex justify-content-between align-items-center justify-content-md-start flex-nowrap"
                >
                  <div className="d-inline-block">
                    <div className="py-md-2 font-15 display-contents">
                      {t('data_entry_step_2.consent_marketing_value')}
                    </div>
                    <div className="py-md-2 display-contents c-891124 cursor-pointer">
                      <Button
                        flavor="transparentPrimary"
                        text={t('data_entry_step_2.consent_privacy_link_2')}
                        classes="p-0 display-contents"
                        classesText="font-15 d-inline border-underline-link"
                        onPress={openInformativePersonalDataLink}
                      />
                    </div>
                    <div className="py-md-2 font-15 display-contents">
                      {t('data_entry_step_2.consent_ending')}
                    </div>
                  </div>
                </Accordion>
              ) : (
                <div className="d-inline-block">
                  <div className="py-md-2 font-15 display-contents">
                    {t('data_entry_step_2.consent_marketing_value')}
                  </div>
                  <div className="py-md-2 display-contents c-891124 cursor-pointer">
                    <Button
                      flavor="transparentPrimary"
                      text={t('data_entry_step_2.consent_privacy_link_2')}
                      classes="p-0 display-contents"
                      classesText="font-15 d-inline border-underline-link"
                      onPress={openInformativePersonalDataLink}
                    />
                  </div>
                  <div className="py-md-2 font-15 display-contents">
                    {t('data_entry_step_2.consent_ending')}
                  </div>
                </div>
              )}
            </div>

            <div className="col-md-6 ps-md-4">
              <InputCheckbox
                name="mainInsured.consentCommunication"
                handleChange={handleChange}
                handleBlur={handleBlur}
                validationSchema={validationSchema}
                touched={touched?.mainInsured?.consentCommunication}
                value={mainInsured?.consentCommunication}
                label={t('data_entry_step_2.consent_communication_label')}
                classesLabel="m-0 font-16 d-flex align-items-baseline mt-3"
              />
              {isMobile ? (
                <Accordion
                  title={t('data_entry_step_3.consent_accordion_label')}
                  classesTitle="m-0 p-0 font-16 me-3"
                  classes="max-w-fit-content"
                  classesHead="m-0 p-0 pb-2 mt-3 d-flex justify-content-between align-items-center justify-content-md-start flex-nowrap"
                >
                  <div className="d-inline-block">
                    <div className="py-md-2 font-15 display-contents">
                      {t('data_entry_step_2.consent_communication_value')}
                    </div>
                    <div className="py-md-2 display-contents c-891124 cursor-pointer">
                      <Button
                        flavor="transparentPrimary"
                        text={t('data_entry_step_2.consent_privacy_link_2')}
                        classes="p-0 display-contents"
                        classesText="font-15 d-inline border-underline-link"
                        onPress={openInformativePersonalDataLink}
                      />
                    </div>
                    <div className="py-md-2 font-15 display-contents">
                      {t('data_entry_step_2.consent_ending')}
                    </div>
                  </div>
                </Accordion>
              ) : (
                <div className="d-inline-block">
                  <div className="py-md-2 font-15 display-contents">
                    {t('data_entry_step_2.consent_communication_value')}
                  </div>
                  <div className="py-md-2 display-contents c-891124 cursor-pointer">
                    <Button
                      flavor="transparentPrimary"
                      text={t('data_entry_step_2.consent_privacy_link_2')}
                      classes="p-0 display-contents"
                      classesText="font-15 d-inline border-underline-link"
                      onPress={openInformativePersonalDataLink}
                    />
                  </div>
                  <div className="py-md-2 font-15 display-contents">
                    {t('data_entry_step_2.consent_ending')}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="ps-md-3 pt-4 pb-4 font-15-semibold">{t('commons.required_field')}</div>
      </div>
    )

    return (
      <div>
        <ScrollTopRoot />
        <div>
          {Title}
          {RadioForm}
          {mainInsured?.isInsured === 'id_yes' && SearchInput}
          {(isSelectedInsured || mainInsured?.isInsured === 'id_no') && (
            <div>
              <div className="col-12 p-0 px-md-3 pl-md-3 mb-2 mb-md-1">
                <div className="mt-4 mb-4 border-top" />
              </div>
              <div>{Insured}</div>
            </div>
          )}
          <div className="col-12 p-0 px-md-3 pl-md-3 mb-2 mb-md-1">
            <div className="mt-4 mb-4 border-top" />
          </div>
          {InsuranceConsents}
        </div>
      </div>
    )
  }
)
